import React from 'react'
import '../root.css';
import './Comentarios.css';
import { getToken } from '../token';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

class Comentarios extends React.Component {
    state = {
        idPost: null,
        comentarios: [],
        comentario : '',
    }

    constructor(props){
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.addPost = this.addPost.bind(this);
        this.handleKeys = this.handleKeys.bind(this);
        this.reportComment = this.reportComment.bind(this);
    }

    handleInput(e){
        this.setState({
            ...this.state,
            comentario : e.target.value
        });

    }

    handleKeys(e){
        if ( e.which === 13){
            this.addPost();
        }
    }

    addPost(){
        var params = new URLSearchParams();
        params.append('token', getToken());
        params.append('uuidObj', this.state.idPost);
        params.append('tipo', 100);
        params.append('mensaje', this.state.comentario);
        
        axios.post(process.env.REACT_APP_Base_Url + 'comentarios/add', params).then(response => {
            this.loading = false;
            //if (response.data.err === 0) {
                this.setState({
                    ...this.state,
                    comentario : '',
                });
                this.loadComentarios(this.state.idPost);
            //}
        });
    }

    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps');
        console.log(nextProps);
        setTimeout(_ => {
            //this.loadPosts(parseInt(nextProps.id));
            //alert(nextProps.id);
            const idPost = parseInt(nextProps.id);
            //alert(idPost);

            this.setState({
                ...this.state,
                idPost: idPost,

            });
            if ( idPost){
                this.loadComentarios(idPost);
            }
        }, 200);
    }

    loadComentarios(idPost) {
        this.loading = true;

        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token', getToken());
        params.append('uuidObj', idPost);
        params.append('tipo', 100);
        //debugger;
        axios.post(process.env.REACT_APP_Base_Url + 'comentarios/listar', params).then(response => {
            this.loading = false;
            if (response.data.err === 0) {
                this.setState({
                    ...this.state,
                    'comentarios': response.data.data.comentarios,
                });

            }
        });
    }

    reportComment(commId){
        if ( window.confirm("¿Reportar Comentario?")){
            var params = new URLSearchParams();
            params.append('token', getToken());
            params.append('uuidObj', commId);
            params.append('razon', 400);//razon default

            axios.post(process.env.REACT_APP_Base_Url + 'reports/addCommentReport', params).then(response => {
                if (response.data.err === 0) {
                    alert('Reporte Enviado');
                }
            });
        }
    }
        
    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }

    render() {
        return (
            <div className="comentariosContainer">
                <div className="listaComentarios bordered flexColumn">
                    {
                        this.state.comentarios.map(comm => {
                            return (
                                <div key={comm.id} className="comentarioItem flexRow">
                                    <Link to={this.amigoUrl(comm.idUsuario,comm.apodo)} key={comm.id} >
                                        <div className="personaComentario flexColumn centerText">
                                            <img src={comm.pathRel || "https://images.chispeame.com/uploads/1119/AAA--111869--05e24592-573b-11eb-9722-001c42d0e01d.jpeg"} alt="Cargando..." />
                                            <b>{comm.apodo}</b>
                                        </div>
                                    </Link>
                                    <p>{comm.comentario}</p>
                                    <FontAwesomeIcon className="reportComment"
                                                onClick={() => this.reportComment(comm.id)}
                                                icon={faExclamationTriangle}></FontAwesomeIcon>
                                        
                                </div>
                            )
                        })
                    }
                </div>
                <div className="addPost">
                    <input 
                        onKeyUp={this.handleKeys}
                        onChange={this.handleInput} 
                        value={this.state.comentario}
                        placeholder="Ingrese su comentario..." />

                    <button                         
                        onClick={this.addPost}>Enviar</button>
                </div>
            </div>
        )
    }
}

export default Comentarios