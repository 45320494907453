import React from 'react'
import '../root.css';

class ProfileTabs extends React.Component{
    tabs =[
        {
            id:1,
            name: 'Posts'
        },
        {
            id:2,
            name: 'Preguntas Anonimas'
        },
        {
            id:3,
            name: 'Amigos'
        },
    ];

    state = {
        activeTab : 'default'
    }

    constructor(props){
        super(props)
        this.changeTab = this.changeTab.bind(this);
    }

    changeTab(tab1){
        if ( this.props.onChange != null){
            this.props.onChange(tab1);
            this.setState({
                ...this.state,
                activeTab : tab1,
            });
        }else{
            console.log('error undefined tab an profile');
        }
    }

    // componentDidUpdate(props2){
    //     console.log(props2);
    //     if ( props2.active !== this.state?.activeTab){
    //         this.setState({
    //             ...this.state,
    //             activeTab : props2.active
    //         });
    //     }
    // }

    render(){
        return (
            <div className="centerText flexRow">
                {(this.tabs.map(tab => {
                    return (
                        (this.state?.activeTab === tab.name.toLowerCase() ||
                            (this.state?.activeTab === 'default' && tab.id === 1)
                        ) ? 
                            <div key={tab.id} onClick={() => this.changeTab(tab.name?.toLowerCase())}
                                className="tabItem highLighted activeTab">
                                {tab.name}
                            </div>
                        : 
                            <div key={tab.id} onClick={() => this.changeTab(tab.name?.toLowerCase())}
                                className="tabItem highLighted">
                                {tab.name}
                            </div>
                        
                    )
                }))}
            </div>
        )
    }
}

export default ProfileTabs