import React from 'react'
import '../root.css';

class PrivacyPolicy extends React.Component{
    render(){
        return (
            <div className="centerText">
                <h2>Politica de Privacidad</h2>
                <p>A nosotros los desarrolladores del sitio nos importa tu privacidad.<br/>
                    Por eso no recolectamos datos ni se los vendemos a terceros.<br/>
                    No tenemos ninguna relacion con otras redes sociales.<br/>
                    Los usuarios solo pueden subir imagenes que sean de ellos o tengan los derechos de distribucion de los mismos.<br/>
                    Cualquier marca es propiedad de sus respectivos duseños.<br/>
                    Si alguien subio una imagen tuya sin tu permiso por favor reportala <br/>
                    en la webapp y mandanos un email a <b>chispeame@hotmail.com</b><br/>
                </p>
            </div>
        )
    }
}

export default PrivacyPolicy