import React from 'react'
import '../root.css';
import './SolicitudesListado.css';
import axios from 'axios';
import {getToken} from '../token';


class SolicitudesListado extends React.Component{
    state = {
        solicitudes : [],
        enviadas : [],
        page : 0,
    };

    constructor(props){
        super(props)

        this.cambiarEstadoSolicitud = this.cambiarEstadoSolicitud.bind(this);
        this.unFriend = this.unFriend.bind(this);
    }

    componentDidMount(){
        this.loadRequests(0);
        this.loadSentRequests(0);
    }

    loadRequests(page=0){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('page', page);

        axios.post(process.env.REACT_APP_Base_Url + 'friends/getAllRequests',params).then(response => {
            console.log(response.data);
            if ( response.data.data.requests){
                this.setState({
                    ...this.state,
                    page : page,
                    solicitudes : response.data.data.requests,
                });
            }
        });
    }

    loadSentRequests(page=0){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('page', page);

        axios.post(process.env.REACT_APP_Base_Url + 'friends/getSentRequests',params).then(response => {
            console.log(response.data);
            if ( response.data.data.requests){
                this.setState({
                    ...this.state,
                    page : page,
                    enviadas : response.data.data.requests,
                });
            }
        });
    }
    unFriend(idOtro,nuevoEstado){
        if ( window.confirm("¿Dejar de ser amigos?")){
            this.cambiarEstadoSolicitud(idOtro,nuevoEstado);
        }
    }
    cambiarEstadoSolicitud(idOtro,nuevoEstado){
        const token = getToken();
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token',token);
        params.append('idOtro',idOtro);
        params.append('status',nuevoEstado);
        

        axios.post(process.env.REACT_APP_Base_Url + 'friends/changeApprovalStatus',params).then(response => {
            this.loading = false;
            if ( response.data.err === 0){
                const lista = this.state.solicitudes;
                
                for(var i=0; i < lista.length;i++){
                    if (parseInt(lista[i].idUser) === parseInt(idOtro)){
                        lista.splice(i,1);
                        break;
                    }
                }
                this.setState({
                    ...this.state,
                    solicitudes : lista,
                });
            }
        });
    }




    render(){
        return (
            <div className="centerText">
                {
                    (this.state.solicitudes.length === 0 && this.state.enviadas.length === 0)?
                        <p>No tienes peticiones de amistad pendientes.</p>
                    :
                        <div className="flexColum centerText">
                            <h2>Peticiones de amistad</h2>
                            
                            {this.state.solicitudes.map(sol => {
                                return (
                                    <div className="friendRequest flexRow">
                                        <img src={sol.pathRel} alt="image123" />
                                        <div className="flexColumn">
                                            <b>{sol.apodo}</b><br/>
                                            {
                                                (sol.estado === 101)?
                                                    <div className="parteDerecha">
                                                        <button onClick={() => this.cambiarEstadoSolicitud(sol.idUser,100)}>Aceptar</button>
                                                        <button onClick={() => this.cambiarEstadoSolicitud(sol.idUser,102)}>Rechazar</button>
                                                    </div>
                                                :
                                                <div className="parteDerecha">
                                                    <button onClick={() => this.unFriend(sol.idUser,101)}>Finalizar Amistad</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            <h2>Peticiones Enviadas</h2>
                            {this.state.enviadas.map(sol => {
                                return (
                                    <div className="friendRequest flexRow">
                                        <img src={sol.pathRel} alt="image123" />
                                        <div className="flexColumn">
                                            <b>{sol.apodo}</b><br/>
                                            {
                                                (sol.estado === 101)?
                                                    // <div className="parteDerecha">
                                                    //     <button onClick={() => this.cambiarEstadoSolicitud(sol.idUser,100)}>Accept</button>
                                                    //     <button onClick={() => this.cambiarEstadoSolicitud(sol.idUser,102)}>Reject</button>
                                                    // </div>
                                                    <b>Pendiente</b>
                                                :
                                                <div className="parteDerecha">
                                                    {/* <button onClick={() => this.unFriend(sol.idUser,101)}>UnFriend</button> */}
                                                    {(sol.estado === 100)?
                                                        <b>Amigos</b>
                                                    : <></>                                                    
                                                    }

                                                    {(sol.estado === 102)?
                                                        <b>Rechazado</b>
                                                    : <></>                                                    
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                }
            </div>
        )
    }
}

export default SolicitudesListado