import React from 'react'
import '../root.css';
import './ProfileStyles.css';
import {getToken} from '../token';
import axios from 'axios';
import Modal from '../Utils/Modal';
import { Link } from 'react-router-dom';

class FriendButton extends React.Component{
    state = {
        friends: 0,
        apodo: 'Cargando...',
        showModal : false,
        friendList : [],        
    }

    constructor(props){
        super(props)
        this.beFriends = this.beFriends.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.mostrarAmigos = this.mostrarAmigos.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        this.goPreviousPage = this.goPreviousPage.bind(this);

        
    }

    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }

    showModal(){
        this.setState({
            ...this.state,
            showModal:true,
        });
    }

    hideModal(){
        this.setState({
            ...this.state,
            showModal:false,
        });   
    }

    beFriends(){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('idOtro',this.props.id);

        axios.post(process.env.REACT_APP_Base_Url + 'friends/sendRequest',params).then(response => {
            console.log(response.data);
            alert('Peticion de amistad enviada');
            if ( this.state.added ){
                return;
            }

            this.setState({
                ...this.state,
                friends : this.state.friends +1,
                added : true,
            });
        });
    }

    mostrarAmigos(page){
        var params = new URLSearchParams();
        params.append('page', page);
        params.append('idUsuario',this.props.id);

        axios.post(process.env.REACT_APP_Base_Url + 'friends/getUserFriends',params).then(response => {
            console.log(response);
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    friendList: response.data.data.friends,
                    page : page,
                });
                this.showModal();
            }
        });

    }

    componentDidUpdate(prevProps) {
        console.log(prevProps);
        console.log(this.props);
        //debugger;
        if ( prevProps.apodo !== this.props.apodo){
            this.setState({
                ...this.state,
                id: this.props.id,
                friends: this.props.friends,
                apodo: this.props.apodo,
            });
            setTimeout(_ => {
                this.hideModal();
            },200);
            
        }
        
    }


    goNextPage(){
        const nextPage = parseInt(this.state.page) + 1;
        this.mostrarAmigos(nextPage);
    }

    goPreviousPage(){
        if ( this.state.page >0){
            const nextPage = parseInt(this.state.page)-1;
            this.mostrarAmigos(nextPage);
        }
    }

    render(){
        return (
            <>
                <Modal show={this.state?.showModal} handleClose={this.hideModal}>
                    <div className="flexColumn">
                        Amigos de {this.props?.apodo}<br/>
                        <div className="fullWidth flexWrap centerText">
                            {this.state?.friendList.map(amigo => {
                                return (<Link onClick={this.handleClose} key={amigo.id} to={this.amigoUrl(amigo.idUser,amigo.apodo)}>
                                    <div  className="person">
                                        {(amigo.pathRel === null || amigo.pathRel === '' || typeof(amigo.pathRel)=='undefined') ?
                                            <img src={process.env.REACT_APP_Default_Image} alt="oops" />
                                        :
                                        <img src={amigo.pathRel} alt="oops" />
                                        }
                                        <br/>
                                        {(amigo.apodo === null)?
                                            <span>Anon_{amigo.id}</span>
                                            :
                                            <span>{amigo.apodo}</span>
                                        }
                                    </div>
                                </Link>
                                );
                            })}
                        </div>
                        <div className="flexRow">
                            {(this.state.page > 0 || this.state.friendList?.length === 0)?
                                <button className="halfWidth" onClick={this.goPreviousPage}>{'<'}--Anterior Pagina</button>
                            :<></>
                            }
                            {(this.state.friendList?.length > 0)?
                                <button className="halfWidth" onClick={this.goNextPage}>Proxima Pagina--{'>'}</button>
                            :null
                            }
                        </div>
                    </div>
                </Modal>
                <div className="flexRow">
                    <button onClick={() => this.beFriends()} className="btnAmigo">Agregar como amigo</button>
                    <button onClick={() => this.mostrarAmigos(0)} className="btnAmigo">({this.state?.friends})</button>
                </div>
            </>
        )
    }
}

export default FriendButton