import React from 'react'
import '../root.css';
import './SocialShareStyles.css';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,

    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    PinterestIcon,
} from "react-share";
import { Passers } from "prop-passer";

class SocialShare extends React.Component {
    render() {
        const {
            url = String(window.location),
            title = "Chispeame Social",
            shareImage = "https://www.chispeame.com/favicon.ico",
            size = "2.5em",
        } = this.props;

        const ShareList = Passers({
            url,
            className: "network__share-button",
        })({
            className: "network cursor-pointer hover transition--default",
            title: `Share ${String(window.location)}`,
        })("li");

        return (
            <div className="centerText socialShareDiv flexRow">
                <ShareList>
                    <FacebookShareButton
                        quote={title}
                    >
                        <FacebookIcon
                            size={size}
                        />
                    </FacebookShareButton>

                    <TwitterShareButton
                        title={title}
                    >
                        <TwitterIcon
                            size={size}
                        />
                    </TwitterShareButton>

                    <WhatsappShareButton
                        title={title}
                        separator=":: "
                    >
                        <WhatsappIcon size={size} />
                    </WhatsappShareButton>

                    <PinterestShareButton
                        url={String(window.location)}
                        media={`${shareImage}`}
                        windowWidth={1000}
                        windowHeight={730}
                    >
                        <PinterestIcon size={size} />
                    </PinterestShareButton>
                </ShareList>
            </div>
        )
    }
}

export default SocialShare