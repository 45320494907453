import React from 'react';
import '../root.css';
import './about.css'

class About extends React.Component{
    
    render(){
        return (
            <div className="centerText flexColumn">
                <h1>Acerca de esta pagina Web Social</h1>
                <p className="centerLeftText aboutText">Somos una red social de imagenes.<br/>
                    Aqui puede encontra amigos, imagenes graciosas, y mucho mas.<br/>
                    Toda imagen subida es automaticamente disponible para todos.
                </p>
            </div>
        )
    }
}

export default About;