import "./settingsStyles.css";
import React from 'react'
import '../root.css';
import ReactUploadImage from '../Utils/ReactUploadImage';
import axios from 'axios';
import {getToken} from '../token';
import { Link } from 'react-router-dom';

class Settings extends React.Component{
    render(){
        return (
            <div className="centerText">
                <h2>Configuracion de cuenta</h2>
                
                <Link to="/networks" className="smallFont">(Agrega tus links de redes sociales)</Link>

                <div className="formItemSettings centerText">
                    <label>Apodo</label>
                    <input type="text" onChange={this.handleApodoWrite} value={this.state.apodo}></input>
                    <button onClick={this.saveApodo}>Guardar</button>
                </div>
                {/* <div className="formItemSettings centerText">
                    <label>Login with Gmail Account</label>
                    <button className="gmailButton">Login</button>
                </div> */}
                <div className="formItemSettings centerText avatar">
                    <label>Imagen de perfil</label>
                    <ReactUploadImage  
                        onStart={this.uploading} 
                        onUpdate={this.onPorcentage}
                        onChange={this.imageSelected} 
                        text="seleccionar"></ReactUploadImage>
                    {
                        this.state.imagen != null ?
                            <img src={this.state.imagen} alt="preview" />
                        :
                            <span></span>
                        
                    }
                    {
                        this.state.uploading === true?
                        <b>Enviando...{this.state.porcentaje}%</b>
                        :
                        <span></span>
                    }
                </div>
                <div className="formItemSettings centerText">
                    <label>Acerca de mi</label>
                    <textarea rows="8" onChange={this.handleAboutMeWrite} value={this.state.aboutme}></textarea>
                    <button onClick={this.saveAboutMe}>Guardar</button>
                </div>
            </div>
        )
    }

    onPorcentage(porc){
        //console.log('update %' + porc);
        this.setState({
            ...this.state,
            porcentaje : porc,
        });
    }

    constructor(props){
        super(props);
        
        this.imageSelected = this.imageSelected.bind(this);
        this.uploading = this.uploading.bind(this);
        this.handleApodoWrite = this.handleApodoWrite.bind(this);
        this.saveApodo = this.saveApodo.bind(this);
        this.onPorcentage = this.onPorcentage.bind(this);
        this.handleAboutMeWrite = this.handleAboutMeWrite.bind(this);
        this.saveAboutMe = this.saveAboutMe.bind(this);

        this.state = {
            imagen:null,
            uploading:false,
            apodo: '',
            aboutme:'',
        };
    }

    handleApodoWrite(e){
        this.setState({
            ...this.state,
            apodo: e.target.value
        });
    }

    handleAboutMeWrite(e){
        this.setState({
            ...this.state,
            aboutme: e.target.value
        });
    }

    imageSelected(image){
        console.log(process.env);

        console.log(image);
        if ( image.nombre ){
            this.setState({
                ...this.state,
                imagen : process.env.REACT_APP_BASE_UPLOAD_URL + image.nombre,
                uploading : false,
            });
            this.uploadImagen();
        }
    }
    
    uploading(){
        this.setState({
            ...this.state,
            uploading : true,
        });
    }

    componentDidMount(){
        const token = getToken();
        console.log('local Token:' + token);
        var params = new URLSearchParams();
        params.append('token', token);

        axios.post(process.env.REACT_APP_Base_Url + 'users/getSettings',params).then(response => {
            console.log(response.data);
            if ( response.data.data.settings){
                this.setState({
                    ...this.state,
                    'apodo' : response.data.data.settings.apodo,
                    'imagen' : response.data.data.settings.pathRel,
                    'aboutme': response.data.data.settings.aboutme,
                });
            }
        });
    }

    saveAboutMe(){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('aboutme',this.state.aboutme);

        axios.post(process.env.REACT_APP_Base_Url + 'users/setAboutMe',params).then(response => {
            console.log(response.data);
            if ( response.data.err === 0){
                alert('update ok');
            }
        });
    }

    saveApodo(){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('apodo',this.state.apodo);

        axios.post(process.env.REACT_APP_Base_Url + 'users/setApodo',params).then(response => {
            console.log(response.data);
            if ( response.data.err === 0){
                alert('apodo ok');
            }else{
                alert('ya existe ese apodo, prueba otro');
            }
        });
    }

    uploadImagen(){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('imagen',this.state.imagen);

        axios.post(process.env.REACT_APP_Base_Url + 'users/setAvatar',params).then(response => {
            console.log(response.data);
            if ( response.data.err === 0){
                alert('imagen ok');
            }else{
                alert('Ocurrio un error');
            }
        });
    }
}

export default Settings