import React from 'react'
import '../root.css';
import './Footer.css';
import { Link } from 'react-router-dom';

class Footer extends React.Component{
    render(){
        return (
            <div className="panelStyle">
                <div className="footer">
                    <div className="twoCols">
                        <ul className="listaFooter">
                            <li><Link to="/">Principal</Link></li>
                            <li><Link to="/about">Acerca De</Link></li>
                            <li><Link to="/policy">Politica de Privacidad</Link></li>
                        </ul>
                    </div>
                    <div className="twoCols">
                        <ul className="listaFooter">
                            <li><Link to="/">Principal</Link></li>
                            <li><Link to="/about">Acerca De</Link></li>
                            <li><Link to="/policy">Politica de Privacidad</Link></li>
                        </ul>
                    </div>
                </div>
                <p className="centerText smallFont">No tenemos ninguna relacion con otras redes sociales.</p>
            </div>
        )
    }
}

export default Footer