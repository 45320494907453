import React from 'react'
import './headerTop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {getToken, setMe, setToken} from './token';

class HeaderTop extends React.Component{
    state = {
        showMenu: false,
        showNotifs : false,
        notifs : [],
    }

    constructor(props){
        super(props)
        this.changeMenu = this.changeMenu.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleNotifs = this.toggleNotifs.bind(this);
        this.markAllReadNotifs = this.markAllReadNotifs.bind(this);
    }

    componentDidMount(){
        const token = getToken();
        console.log('local Token:' + token);
        var params = new URLSearchParams();
        params.append('token', token);

        axios.post(process.env.REACT_APP_Base_Url + 'logins/validate',params).then(response => {
            console.log('logged in validation');
            if ( response.data.err === 0){
                setToken(response.data.data.token);
                console.log(response.data.data.token);
                setMe(response.data.data.id);//el idUser
                this.setupNotifications();
            }
        });
    }

    setupNotifications(){
        this.loadNotifs();
        setTimeout(_ => {
            this.loadNotifs();
        },60*1000);
    }


    loadNotifs(){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);

        axios.post(process.env.REACT_APP_Base_Url + 'notificaciones/get10Unread',params).then(response => {
            if ( response.data.err === 0){
                console.log(response.data);
                this.setState({
                    ...this.state,
                    notifs: response.data.data.notificaciones,
                });
            }
        });
    }

    markAllReadNotifs(){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);

        axios.post(process.env.REACT_APP_Base_Url + 'notificaciones/marcarTodasLeida',params).then(response => {
            if ( response.data.err === 0){
                console.log(response.data);
                this.setState({
                    ...this.state,
                    notifs: [],
                });
            }
        });
    }

    changeMenu(seccion){

    }

    toggleMenu(){
        this.setState({
            ...this.state,
            showMenu : ! this.state.showMenu,
        });
    }

    toggleNotifs(){
        this.setState({
            ...this.state,
            showNotifs : ! this.state.showNotifs,
        });
    }
    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }

    render(){
        return (
            <>
                <div className="headerTop">
                    <div className="logo">
                        <Link to="/">Chispeame.com</Link>
                    </div>
                    <div className="botonesRight">
                        {/* <div onClick={this.toggleMenu}>
                            <FontAwesomeIcon icon={faUser} />
                        </div> */}
                        <div onClick={this.toggleNotifs}>
                            <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                            <span className="badge badge-primary smallBadge">({
                                (this.state.notifs.length > 0)?
                                    (this.state.notifs.length <= 9)?
                                        <b>{this.state.notifs.length}</b>
                                    :
                                        <b>9+</b>
                                :
                                    (<b>-</b>)
                            })</span>
                        </div>
                    </div>
                </div>    
                <div className="navigation">
                    <Link to="/">Principal</Link> | 
                    {/* <Link to="/profile/@me">My Profile</Link> |  */}
                    <Link to="/friends">Amigos</Link> |  
                    <Link to="/browse">Explorar</Link> |  
                    <Link to="/settings">Configuracion</Link> |
                    <Link to="/about">Acerca de</Link> |
                    <Link to="/help">Ayuda</Link>
                </div>
                {
                this.state.showMenu
                    ? (
                    <div className="menuLogin flexColumn">
                        <div>Login</div>
                        <div>Registrar Cuenta</div>
                        <div>Salir</div>
                    </div>
                    )
                    : (
                    null
                    )
                }
                {
                this.state.showNotifs
                    ? (
                    <div className="menuLogin flexColumn">
                        <div className="centerText" onClick={this.toggleNotifs}>(Cerrar Menu)</div>
                        {
                            (this.state.notifs.length === 0)?
                                <div>Sin notificaciones nuevas</div>
                            :
                            <>
                                <div className="centerText" onClick={this.markAllReadNotifs}>Marcan todas como leidas</div>
                                {this.state.notifs.map(notif => {
                                    return <div>
                                        {/* comentaron en tu post */}
                                        {(parseInt(notif.tipo) === 100) ?
                                            notif.texto
                                        :null}

                                        {/* peticion de amistad */}
                                        {(parseInt(notif.tipo) === 303) ?
                                            <Link to="/">{notif.texto}</Link>                                            
                                        :null}
                                        {/* te aceptaron como amigo */}
                                        {(parseInt(notif.tipo) === 304) ?
                                            <Link to={this.amigoUrl(notif.uuidObj,'friend')}>{notif.texto}</Link>                                            
                                        :null}
                                        {/* te hicieron una pregunta anonima */}
                                        {(parseInt(notif.tipo) === 305) ?
                                            <Link to={this.amigoUrl(notif.uuidObj,'friend')}>{notif.texto}</Link>
                                        :null}

                                        {/* te respondieron una pregunta anonima */}
                                        {(parseInt(notif.tipo) === 306) ?
                                            <Link to={this.amigoUrl(notif.uuidObj,'friend')}>{notif.texto}</Link>
                                        :null}
                                    </div>
                                })}
                            </>
                        }
                        
                    </div>
                    )
                    : (
                    null
                    )
                }      
            </>
        );
    }
}

export default HeaderTop