import './modal.css';
import React from 'react';

// const Modal = ({ handleClose, show, children }) => {
//   const showHideClassName = show ? "modal display-block" : "modal display-none";

//   useEffect(() => {
//     //did mount
//     console.log('did mount');
//     document.addEventListener('click', (event) =>{
//       //no detectar si hago click dentro del modal.
//       var ignoreClickOnMeElement = document.getElementById('modal1');
//       var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
//       if (!isClickInsideElement) {
//         show = false;
//         alert('closing');
//       }
//     });
//     return () => {
//       //did unmount.
//       document.removeEventListener('click',() => {});
//     };
//   },[]);

//   return (
//     <div id="modal1" className={showHideClassName}>
//       <section className="modal-main">
//         {children}
//         <button type="button" className="centerText" onClick={handleClose}>
//           Cerrar
//         </button>
//       </section>
//     </div>
//   );
// };
class Modal extends React.Component{

  constructor(props){
    super(props)
    console.log(props);


    //this.handleKeyUp = this.handleKeyUp.bind(this);
    //this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  // Handle the key press event.
  // handleKeyUp(e) {
  //   //const { onCloseRequest } = this.props;
  //   const keys = {
  //     27: () => {
  //       e.preventDefault();
  //       //onCloseRequest();
  //       // this.setState({
  //       //   show:false,
  //       // });
  //       console.log('esc');
  //       this.props.show = false;
  //       window.removeEventListener('keyup', this.handleKeyUp, false);
  //     },
  //   };

  //   if (keys[e.keyCode]) { keys[e.keyCode](); }
  // }
  
  // Handle the mouse click on browser window.
  // handleOutsideClick(e) {
  //   debugger;
  //   if (this.modal) {
  //     if (!this.modal.contains(e.target)) {
  //       //onCloseRequest();
  //       this.setState({
  //         salir:true,
  //       });
  //       document.removeEventListener('click', this.handleOutsideClick, false);
  //     }
  //   }
  // }

  render(){
    return (
          
      (this.props.show)?
        (<>
          {/* <div onClick={this.props.handleClose} className="fondoModal"></div> */}
          <div className="modal display-block">
            <section className="modal-main">
              {this.props.children}
              <div className="centerText">
                <button type="button"  onClick={this.props.handleClose}>
                  Close
                </button>
              </div>
              
            </section>
          </div>
        </>
        )
      :
        <></>
          
    );
  }
}

// class Modal extends Component{
//   state = {
//     show: false,
//   }

//   constructor(props){
//     super(props)

    
//     this.handleKeyUp = this.handleKeyUp.bind(this);
//     this.handleOutsideClick = this.handleOutsideClick.bind(this);
//   }

//   // Add listeners immediately after the component is mounted.
//   componentDidMount() {
//     this.setState({
//       show: this.props.show,
//     });

//     // window.addEventListener('keyup', this.handleKeyUp, false);
//     // document.addEventListener('click', this.handleOutsideClick, false);
//   }

//   // Remove listeners immediately before a component is unmounted and destroyed.
//   // componentWillUnmount() {
//   //   window.removeEventListener('keyup', this.handleKeyUp, false);
//   //   document.removeEventListener('click', this.handleOutsideClick, false);
//   // }

//   // Handle the key press event.
//   handleKeyUp(e) {
//     //const { onCloseRequest } = this.props;
//     const keys = {
//       27: () => {
//         e.preventDefault();
//         //onCloseRequest();
//         this.setState({
//           show:false,
//         });
//         window.removeEventListener('keyup', this.handleKeyUp, false);
//       },
//     };

//     if (keys[e.keyCode]) { keys[e.keyCode](); }
//   }
  
//   // Handle the mouse click on browser window.
//   handleOutsideClick(e) {
//     if (this.modal) {
//       if (!this.modal.contains(e.target)) {
//         //onCloseRequest();
//         this.setState({
//           show:false,
//         });
//         document.removeEventListener('click', this.handleOutsideClick, false);
//       }
//     }
//   }

//   render(){
//     return (
      
//         (this.state.show)?
//         (  <div className="modal display-block">
//           <section className="modal-main">
//             {this.props.children}
//             <button type="button" className="centerText" onClick={this.props.handleClose}>
//               Close
//             </button>
//           </section>
//         </div>)
//         :
//         <></>
      
//     );
//   }
  
// };

export default Modal;
//https://www.digitalocean.com/community/tutorials/react-modal-component
//https://www.digitalocean.com/community/tutorials/react-modal-component