import React from 'react'
import '../root.css';

class Working extends React.Component{
    render(){
        return (
            <div className="centerText">
                <h2>En construccion</h2>
                <p>Estamos trabajando en esta funcionalidad...
                </p>
                <img className="mediumImage" src={process.env.PUBLIC_URL + "/assets/icons/wip.png"} alt="wip" />
            </div>
        )
    }
}

export default Working