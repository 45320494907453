import React from 'react'
import '../root.css';
import './PostsList.css';
import {getToken} from '../token';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from '../Utils/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faComment,faThumbsUp, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Comentarios from '../Comentarios/Comentarios';

class PostsList extends React.Component{
    state = {
        loading: false,
        idPost: null,
        posts: [],
        page : 0,
        showModal: false,
        imageToZoom : null,
        mode: 'trending',
        usuario : [],
    };

    constructor(props){
        super(props);
        //binds?
        this.like = this.like.bind(this);
        this.zoomImage = this.zoomImage.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.doLoadMorePosts = this.doLoadMorePosts.bind(this);
        
    }


    like(idPost){
        //alert('liking id Post #' + idPost);
        this.setState({
            ...this.state,
            liking: true,
            likinkPost: idPost
        });

        var params = new URLSearchParams();
        params.append('token', getToken());
        //el idUsuariodel que estoy buscando.
        params.append('uuidObj',idPost);
        params.append('tipo',100);
        params.append('voto',1);
        
        axios.post(process.env.REACT_APP_Base_Url + 'votos/votar',params).then(response => {
            this.state.posts.forEach(element => {
                if ( parseInt(element.id) === this.state.likinkPost){
                    element.likes = parseInt(element.likes) + 1; 
                } 
            });

            this.setState({
                ...this.state,
                liking: false,
                likinkPost: null,
            }); 
        });

        // setTimeout( _ => {
        //     this.setState({
        //         ...this.state,
        //         liking: false,
        //         likinkPost: null,
        //     }); 
        // },2000);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            mode: this.props.mode,
        });

        setTimeout(_ => {
            this.loadPosts(this.props.id,this.props.mode);                    
        },200);
        
    }

    // componentDidUpdate(props2){
    //     console.log(props2);
    //     if ( props2.idUsuario !== this.props.id){
    //         this.setState({
    //             ...this.state,
    //             mode: props2.mode,
    //         });
    
    //         setTimeout(_ => {
    //             this.loadPosts(props2.idUsuario,props2.mode);                    
    //         },200);
    //     }
        
    // }

    loadPosts(id,mode){
        if ( typeof(mode) == 'undefined'){
            return;
        }

        this.setState({
            ...this.state,
            id: id,
            mode : mode,
        });
        
        if ( mode === 'trending'){
            this.loadMorePostsFromTrending(0);
        }else if (mode === 'latest'){
            this.loadMoreLatestPosts(0);
        }else{
            this.loadMorePostsFromUser(0);
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps');
        console.log(nextProps);
        setTimeout(_ => {
            this.loadPosts(parseInt(nextProps.id),nextProps.mode);
        },200);
          
    }

    doLoadMorePosts(){
        //debugger;
        console.log('load more de:' + this.state.mode);

        if ( this.state.mode === 'trending'){
            this.loadMorePostsFromTrending(this.state.page);
        }else if (this.state.mode === 'latest'){
            this.loadMoreLatestPosts(this.state.page);
        }else{
            this.loadMorePostsFromUser(this.state.page);
        }
    }

    loadMoreLatestPosts(page){
        this.setState({
            ...this.state,
            loading:true,
        });

        var params = new URLSearchParams();
        params.append('token', getToken());
        params.append('page',page);

        axios.post(process.env.REACT_APP_Base_Url + 'posts/getLatest',params).then(response => {
            if ( response.data.err === 0){
                if ( page === 0){
                    this.setState({
                        ...this.state,
                        'posts': response.data.data.posts,
                        'page': page+1,
                        loading : false,
                    });
                }else{
                    const posts2 = this.state.posts;
                    response.data.data.posts.forEach(element => {
                        posts2.push(element);
                    });
    
                    this.setState({
                        ...this.state,
                        'posts': posts2,
                        'page': page+1,
                        loading : false,
                    });
                }
                
                
            }
        });
    }

    loadMorePostsFromUser(page){
        this.setState({
            ...this.state,
            loading:true,
        });

        var params = new URLSearchParams();
        params.append('token', getToken());
        //el idUsuariodel que estoy buscando.
        params.append('idUsuario',this.props.idUsuario);
        params.append('page',page);

        axios.post(process.env.REACT_APP_Base_Url + 'posts/getFromUser',params).then(response => {
            if ( response.data.err === 0){
                if ( page === 0){
                    this.setState({
                        ...this.state,
                        'posts': response.data.data.posts,
                        'page': page+1,
                        loading : false,
                    });
                }else{
                    const posts2 = this.state.posts;
                    response.data.data.posts.forEach(element => {
                        posts2.push(element);
                    });
    
                    this.setState({
                        ...this.state,
                        'posts': posts2,
                        'page': page+1,
                        loading : false,
                    });
                }
                
                
            }
        });
        
    }

    loadMorePostsFromTrending(page){
        this.setState({
            ...this.state,
            loading:true,
        });

        var params = new URLSearchParams();
        params.append('token', getToken());
        params.append('page',page);
        
        axios.post(process.env.REACT_APP_Base_Url + 'posts/getPostsFromTrending',params).then(response => {
            if ( response.data.err === 0){
                if ( page === 0){
                    this.setState({
                        ...this.state,
                        'posts': response.data.data.posts,
                        'page': page+1,
                        loading : false,
                    });
                    
                }else{
                    const posts2 = this.state.posts;
                    response.data.data.posts.forEach(element => {
                        posts2.push(element);
                    });
    
                    this.setState({
                        ...this.state,
                        'posts': posts2,
                        'page': page+1,
                        loading : false,
                    });
                }
                
            }
        });
    }

    loadUsuarioFromPost(idPost){
        
        var params = new URLSearchParams();
        params.append('token', getToken());
        params.append('idPost','' + idPost);
        
        axios.post(process.env.REACT_APP_Base_Url + 'imagenes/getUserDePost',params).then(response => {
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    'usuario': response.data.data.user,
                });
            }
        });
    }

    zoomImage(img,idPost){
        this.showModal(img,idPost);
        this.loadUsuarioFromPost(idPost);
    }

    showModal(img,idPost){
        this.setState({
            ...this.state,
            showModal:true,
            imageToZoom : img,
            idPost: idPost,
        });
    }

    hideModal(){
        this.setState({
            ...this.state,
            showModal:false,
            imageToZoom : null,
            idPost: null,
        });   
    }

    reportPost(commId){
        if ( window.confirm("¿Reportar Post?")){
            var params = new URLSearchParams();
            params.append('token', getToken());
            params.append('uuidObj', commId);
            params.append('razon', 400);//razon default

            axios.post(process.env.REACT_APP_Base_Url + 'reports/addPostReport', params).then(response => {
                if (response.data.err === 0) {
                    alert('Reporte enviado');
                }
            });
        }
    }

    changeMode(modo){
        this.setState({
            ...this.state,
            mode: modo,
            page : 0,
            loading: true,
            posts: [],
        });

        setTimeout(_ => {
            this.loadPosts(0,modo);
        }, 200);

        
    }
    
    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }

    render(){
        return (
            <>
            <br/>
            {
                (this.state.mode === "trending" || this.state.mode === "latest")?
                    <>
                        <div className="menuModoPost centerText flexRow">
                            <div onClick={() => this.changeMode('trending')}>Tendencia</div>
                            <div onClick={() => this.changeMode('latest')}>Ultimas</div>
                        </div>
                        <br/>
                    </>
                :
                    null
            }

            <div className="flexColumn">

                <div className="flexWrap postListContainer">
                   

                    <Modal show={this.state.showModal} handleClose={this.hideModal}>
                        <FontAwesomeIcon className="reportPost"
                                onClick={() => this.reportPost(this.state.idPost)}
                                icon={faExclamationTriangle}></FontAwesomeIcon>
                                
                        <div className="containerModal flexRow">
                            {
                                (this.state.imageToZoom != null && this.state.imageToZoom !== 'null')?
                                    <div className="halfWidth">
                                        <Link to={this.amigoUrl(this.state.usuario?.id,this.state.usuario?.apodo)} key={this.state.usuario?.id} >
                                            <div className="personaComentario flexRow centerText">
                                                <img src={this.state.usuario?.pathRel || "https://images.chispeame.com/uploads/1119/AAA--111869--05e24592-573b-11eb-9722-001c42d0e01d.jpeg"} alt="Cargando..." />
                                                <b>{this.state.usuario?.apodo}</b>
                                            </div>
                                        </Link>
                                        <img onClick={this.hideModal} 
                                            src={this.state.imageToZoom} className="imagenZoom" alt="Loading..." />
                                    </div>
                                :
                                    <></>
                            }
                            <Comentarios id={this.state.idPost}/>
                        </div>
                    </Modal>
                    

                    {(this.state.posts && this.state.posts.length >0)?
                        this.state.posts.map(post => {
                            return (
                                // aca va el post.
                                <div key={post.id} className="post bordered postCols centerText">
                                    <p>{
                                        post.texto
                                    }</p>
                                    {
                                        (post.pathRel != null && post.pathRel !== 'null')?
                                            <img onClick={() => this.zoomImage(post.pathRel,post.id)} src={post.pathRel} alt="img" />    
                                        :
                                            <></>
                                    }
                                    <div className="botonesPost">
                                        <button onClick={() => this.zoomImage(post.pathRel,post.id)}>
                                            {post.comentarios}
                                            <FontAwesomeIcon icon={faComment} />
                                        </button>
                                        <button onClick={()=> this.like(post.id)}>
                                            {post.likes}
                                            <FontAwesomeIcon icon={faThumbsUp} />
                                            {
                                                (this.state.liking && post.id === this.state.likinkPost) ?
                                                    <FontAwesomeIcon className="rotator" icon={faSpinner} />
                                                :
                                                    <></>
                                            }
                                            
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className="centerText">
                            Este usuario no tienes Posts publicos todavia.
                        </div>
                    }
                

                
                {/* el loading */}
                {(this.state.loading)?
                    <div className="flexRow centerText loadingDiv">
                        <br/>
                        <b>Cargando...</b>
                    </div>
                :
                    <span></span>
                }
            </div>
                {
                (this.state.posts.length > 0)?
                    <div className="flexRow centerText">
                        <button onClick={this.doLoadMorePosts}>Mas Posts...</button>
                    </div>
                : null
                }
        </div>
        </>
        )
    }
}

export default PostsList