import React from 'react'
import '../root.css';
import './coolPeopleStyles.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

class CoolPeople extends React.Component{
    state = {
        'cool' : [],
    };

    componentDidMount(){
        this.loadCoolPeople();
    }

    loadCoolPeople(){
        //pedir aca el usuario.
        var params = new URLSearchParams();
        //params.append('idUsuario', idUser);

        axios.post(process.env.REACT_APP_Base_Url + 'users/coolPeople',params).then(response => {
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    'cool':response.data.data.cool,
                });
                
            }
        });
    }
    
    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }

    render(){
        return (
            <div className="centerText coolPeople">
                <Link to="/browse"><span className="largeFont">Gente copada para conocer</span></Link>
                <hr/>
                <div className="personas">
                    {
                        this.state.cool.map(amigo => {
                            return <Link key={amigo.id} to={this.amigoUrl(amigo.id,amigo.apodo)}>
                                <div  className="person">
                                    {(amigo.pathRel === null || amigo.pathRel === '' || typeof(amigo.pathRel)=='undefined') ?
                                        <img src={process.env.REACT_APP_Default_Image} alt="oops" />
                                    :
                                    <img src={amigo.pathRel} alt="oops" />
                                    }
                                    <br/>
                                    {(amigo.apodo === null)?
                                        <span>Anon_{amigo.id}</span>
                                        :
                                        <span>{amigo.apodo}</span>
                                    }
                                </div>
                            </Link>
                        })
                    }
                    

                </div>
            </div>
        )
    }
}

export default CoolPeople