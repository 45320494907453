import React from 'react'
import '../root.css';
import axios from 'axios';
import {getToken} from '../token';

class Networks extends React.Component{
    state = {
        networks : null
    };

    constructor(props){
        super(props);
        //los binds.
        this.handleYoutubeKeys = this.handleYoutubeKeys.bind(this);
        this.handleTwitchKeys = this.handleTwitchKeys.bind(this);
        this.handleSpotifyKeys = this.handleSpotifyKeys.bind(this);
        this.handleTwitterKeys = this.handleTwitterKeys.bind(this);
        this.handleFacebookKeys = this.handleFacebookKeys.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        this.loadNetworks();
    }

    handleYoutubeKeys(e){
        this.setState({
            ...this.state,
            youtube: e.target.value
        });
    }

    handleTwitchKeys(e){
        this.setState({
            ...this.state,
            twitch: e.target.value
        });
    }

    handleSpotifyKeys(e){
        this.setState({
            ...this.state,
            spotify: e.target.value
        });
    }

    handleTwitterKeys(e){
        this.setState({
            ...this.state,
            twitter: e.target.value
        });
    }

    handleFacebookKeys(e){
        this.setState({
            ...this.state,
            facebook: e.target.value
        });
    }

    save(){
        if (!this.validateInput(this.state.facebook,'facebook.com')){
            alert('Direccion invalida: ' + this.state.facebook);
            return;
        }

        if (!this.validateInput(this.state.twitter,'twitter.com')){
            alert('Direccion invalida: ' + this.state.twitter);
            return;
        }

        if (!this.validateInput(this.state.spotify,'spotify.com')){
            alert('Direccion invalida: ' + this.state.spotify);
            return;
        }

        if (!this.validateInput(this.state.twitch,'twitch.com')){
            alert('Direccion invalida: ' + this.state.twitch);
            return;
        }

        if (!this.validateInput(this.state.youtube,'youtube.com')){
            alert('Direccion invalida: ' + this.state.youtube);
            return;
        }
        //alert("pendiente de implementacion");
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('facebook',this.state.facebook);
        params.append('twitter',this.state.twitter);
        params.append('spotify',this.state.spotify);
        params.append('twitch',this.state.twitch);
        params.append('youtube',this.state.youtube);


        axios.post(process.env.REACT_APP_Base_Url + 'users/setNetworks',params).then(response => {
            console.log(response.data);
            if ( response.data.err === 0){
                alert('Guadado OK');
            }
        });
    }

    validateInput(url, domain){
        if (url === ''){
            return true;
        }
        
        if ( url != null){
            const res = (url.indexOf(domain) > -1)
            return res;

        }
        return false
    }

    loadNetworks(){
        const token = getToken();
        console.log('local Token:' + token);
        var params = new URLSearchParams();
        params.append('token', token);

        axios.post(process.env.REACT_APP_Base_Url + 'users/getNetworks',params).then(response => {
            console.log(response.data);
            if ( response.data.data.networks){
                this.setState({
                    ...this.state,
                    'youtube' : response.data.data.networks.youtube,
                    'twitch' : response.data.data.networks.twitch,
                    'spotify' : response.data.data.networks.spotify,
                    'twitter' : response.data.data.networks.twitter,
                    'facebook' : response.data.data.networks.facebook,

                });
            }
        });
    }

    render(){
        return (
            <div className="centerText">
                <h2>Tus links a redes sociales</h2>
                <p>Aca puedes mostrar tus redes sociales<br/>
                    para que otros usuarios las visiten.<br/>
                </p>
                <div className="formItemSettings centerText">
                    <label>Youtube</label>
                    <input type="text" 
                        placeholder="https://site.com/"
                        onChange={this.handleYoutubeKeys} value={this.state.youtube}></input>
                </div>
                <div className="formItemSettings centerText">
                    <label>Twitter</label>
                    <input type="text" 
                        placeholder="https://site.com/"
                        onChange={this.handleTwitterKeys} value={this.state.twitter}></input>
                </div>
                <div className="formItemSettings centerText">
                    <label>Facebook</label>
                    <input type="text" 
                        placeholder="https://site.com/"
                        onChange={this.handleFacebookKeys} value={this.state.facebook}></input>
                </div>
                <div className="formItemSettings centerText">
                    <label>Twitch</label>
                    <input type="text" 
                        placeholder="https://site.com/"
                        onChange={this.handleTwitchKeys} value={this.state.twitch}></input>
                </div>
                <div className="formItemSettings centerText">
                    <label>Spotify</label>
                    <input type="text" 
                        placeholder="https://site.com/"
                        onChange={this.handleSpotifyKeys} value={this.state.spotify}></input>
                </div>
                <br/>
                <div className="centerText">
                    <button onClick={this.save}>Guardar</button>
                </div>
            </div>
        )
    }
}

export default Networks