import React from 'react'
import '../root.css';
import './Preguntas.css';
import axios from 'axios';
import {getMe, getToken} from '../token';
import AddPregunta from './AddPregunta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faReply} from '@fortawesome/free-solid-svg-icons';


class Preguntas extends React.Component{
    state = {
        idUsuario : 0,
        preguntas : null,
        loading : true,
    };

    constructor(props){
        super(props)
        this.reload = this.reload.bind(this);
        this.reply = this.reply.bind(this);
        this.itsMe = this.itsMe.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);


        this.state = {
            ...this.state,
            idUsuario: this.props.idUsuario,
        };

    }
    
    componentDidUpdate(prevProps) {
        console.log(prevProps);
        console.log(this.props);
        //debugger;
        if ( prevProps.idUsuario !== this.props.idUsuario){
            this.setState({
                ...this.state,
                idUsuario: this.props.idUsuario,
            });

            this.loadPreguntas(this.props.idUsuario,0);
        }
        
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            idUsuario: this.props.idUsuario,
        });

        this.loadPreguntas(this.props.idUsuario,0);
    }

    loadPreguntas(idOp,page){
        this.setState({
            ...this.state,
            loading:true,
            page: page,
        });
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token',getToken());
        params.append('idUsuario', idOp);
        params.append('page',page);

        axios.post(process.env.REACT_APP_Base_Url + 'preguntas/getAll',params).then(response => {

            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    loading: false,
                    preguntas: response.data.data.preguntas,
                    page: page,
                });
            }
        });
    }

    reload(){
        this.loadPreguntas(this.props.idUsuario,0);
    }

    nextPage(){
        let pagina = this.state.page +1;
        this.loadPreguntas(this.props.idUsuario,pagina);
    }

    previousPage(){
        let pagina = this.state.page -1;
        if ( pagina >= 0){
            this.loadPreguntas(this.props.idUsuario,pagina);
        }
    }

    reply(idPreg,preg){
        let respuesta = window.prompt(preg);
        if ( respuesta?.trim() !== '' && respuesta !== null){   
            var params = new URLSearchParams();
            params.append('token',getToken());
            params.append('idPregunta', idPreg);
            params.append('respuesta',respuesta);

            axios.post(process.env.REACT_APP_Base_Url + 'preguntas/response',params).then(response => {
                if ( response.data.err === 0){
                    this.reload();
                }
            });
        }
    }

    itsMe(idOtro){
        //debugger;
        const me = getMe();
        return ( parseInt(me) === idOtro);
    }

    render(){
        return (
            <div className="flexColumn">
                <p>Toda Pregunta es anonima pero cualquiera la puede ver.<br/>
                    Solo que no dice quien realizo la pregunta.
                </p>
                {
                    (this.state.preguntas?.length > 0)?
                        (this.state.preguntas?.map(preg => {
                            return <div key={preg.id} className="preguntaItem fullWidth flexRow highLighted">
                                <img src={process.env.PUBLIC_URL + "/assets/icons/pregunta.png"}
                                    alt="avatar?" />
                                <div style={{'width': '80%'}}>
                                    <div className="pregunta">{preg.pregunta}</div>
                                    <div className="respuesta">{preg.respuesta}</div>
                                </div>
                                {(this.itsMe(preg.idUsuarioOp) && preg.respuesta === null)?
                                    <div className="reply" onClick={() => this.reply(preg.id,preg.pregunta)}>
                                        <FontAwesomeIcon icon={faReply} />
                                    </div>
                                :null}
                                
                            </div>
                        }))
                    : <b>No tiene preguntas todavia, puedes hacerle una pregunta si quieres.</b>
                }
                {
                    (this.state.page > 0)?
                        <button onClick={this.previousPage}>{'<'}--Anterior Pagina</button>
                    :null
                }
                {
                    (this.state.preguntas?.length > 0)?
                        <button onClick={this.nextPage}>Proxima Pagina--{'>'}</button>
                    :null
                }
                
                <AddPregunta idUsuario={this.props.idUsuario} onPreguntado={this.reload} />
            </div>
        )
    }
}

export default Preguntas