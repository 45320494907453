import React from 'react'
import '../root.css';
import './thinkingStyles.css';
import ReactUploadIcon from '../Utils/ReactUploadIcon';
import { getToken } from '../token';
import axios from 'axios';

class Thinking extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            message : props.message,
            uploading : false,
            texto : '',
            imagen: null,
        };

        this.imageSelected = this.imageSelected.bind(this);
        this.uploading = this.uploading.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.sendThinking = this.sendThinking.bind(this);
        this.onPorcentage = this.onPorcentage.bind(this);
        
    }

    componentDidMount(){
        if ( this.state.message == null){
            this.setState({
                message : '¿Que estas pensando ahora?',
                uploading : false,
                texto : '',
                imagen : null,
            });
        }
    }

    sendThinking(){
        if ( this.state.uploading ){
            alert('Debes esperar que termine la subida de la imagen =)');
            return;
        }
        if ( this.state.texto !== ''){
            // if ( this.state.imagen !== null){
            //     //TODO: mandar y llamar futuro callback.
            //     alert(this.state.texto + " img=>" + this.state.imagen);
            // }else{
            //     //TODO: mandar y llamar futuro callback.
            //     alert(this.state.texto);
            // }

            var params = new URLSearchParams();
            params.append('token', getToken());
            params.append('texto',this.state.texto);
            params.append('pathRel',this.state.imagen);
            
            axios.post(process.env.REACT_APP_Base_Url + 'posts/addPost',params).then(response => {
                if ( response.data.err === 0){
                    this.setState({
                        ...this.state,
                        texto : '',
                        imagen: null,                        
                        loading : false,
                    });
                    
                }

                //debugger;
                //aviso que hay nuevo posteo.
                if ( this.props.onNewPost){
                    this.props.onNewPost();
                }
            });
            
        }else{
            alert("Debe escribir algo.");
        }
    }

    imageSelected(image){
        console.log(process.env);

        console.log(image);
        if ( image.nombre ){
            this.setState({
                ...this.state,
                imagen : process.env.REACT_APP_BASE_UPLOAD_URL + image.nombre,
                uploading : false,
            });
        }
    }
    
    uploading(){
        this.setState({
            ...this.state,
            uploading : true,
        });
    }

    handleInput(event){
        this.setState({
            ...this.state,
            texto: event.target.value
        });
    }
    onPorcentage(porc){
        console.log('update %' + porc);
        this.setState({
            ...this.state,
            porcentaje : porc,
        });
    }
    

    render(){
        return (
            <div className="centerText thinkingContainer">
                <div className="topThinking">
                    <textarea 
                        value={this.state.texto}
                        onChange={this.handleInput} 
                        placeholder={this.state.message} ></textarea>
                    <div>
                        {
                            (this.state.imagen != null && this.state.uploading === false) ?
                                <img src={this.state.imagen} alt="preview" />
                            :
                                <ReactUploadIcon
                                    onUpdate={this.onPorcentage} 
                                    onStart={this.uploading} 
                                    onChange={this.imageSelected} text="Agregar imagen"/>
                            
                        }
                        {
                            this.state.uploading === true?
                                <b>Enviando<br/>{this.state.porcentaje}%</b>
                            :
                                <span></span>
                        }
                    </div>
                </div>
                
                <div className="botonPublicar">
                    <button onClick={this.sendThinking}>Enviar</button>
                </div>
            </div>
        )
    }
}

export default Thinking