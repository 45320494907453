import React from 'react'
import '../root.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

class FriendsMore extends React.Component{
    state = {
        friends: [],
        query : '',
        page : 0,
        mode : 'friends',
        idUser : 0,
        apodo: 'Cargando...',
    };

    constructor(props){
        super(props);
        this.goNextPage = this.goNextPage.bind(this);
        this.goPreviousPage = this.goPreviousPage.bind(this);

        this.setState({
            ...this.state,
            idUser : this.props.match.params.id,
            apodo : this.props.match.params.apodo,
        });
    }

    componentDidMount(){
        setTimeout(_ => {
            this.setState({
                ...this.state,
                idUser : this.props.match.params.id,
                apodo : this.props.match.params.apodo,
            });

            this.getSusAmigos(this.props.match.params.id,0);
        },200);
        
    }

    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps');
        setTimeout(_ => {
            this.setState({
                ...this.state,
                idUser : this.props.match.params.id,
                apodo : this.props.match.params.apodo,
            });

            this.getSusAmigos(nextProps.match.params.id,0);
        },200);
          
    }

    getSusAmigos(idUser,page){
        var params = new URLSearchParams();
        params.append('page', page);
        params.append('idUsuario',idUser);

        axios.post(process.env.REACT_APP_Base_Url + 'friends/getUserFriends',params).then(response => {
            console.log(response);
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    friends: response.data.data.friends,
                    page : page,
                    mode: 'friends',
                });
            }
        });
    }

    


    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }
    
    goNextPage(){
        const nextPage = parseInt(this.state.page) + 1;
        this.getSusAmigos(this.state.idUser,nextPage);
    }

    goPreviousPage(){
        if ( this.state.page >0){
            const nextPage = parseInt(this.state.page)-1;
            this.getSusAmigos(this.state.idUser,nextPage);
        }
    }

    render(){
        return (
            <div className="centerText">
                <h2>Amigos de {this.state.apodo}</h2>
                <div className="usersList usersList">
                    <div className="personas">
                        {
                            (this.state.friends.length === 0)?
                                <h2>No tiene amigos todavia.</h2>
                            : null
                        }
                        
                        {
                            this.state.friends.map(amigo => {
                                return (<Link key={amigo.id} to={this.amigoUrl(amigo.idUser,amigo.apodo)}>
                                    <div  className="person">
                                        {(amigo.pathRel === null || amigo.pathRel === '' || typeof(amigo.pathRel)=='undefined') ?
                                            <img src={process.env.REACT_APP_Default_Image} alt="oops" />
                                        :
                                        <img src={amigo.pathRel} alt="oops" />
                                        }
                                        <br/>
                                        {(amigo.apodo === null)?
                                            <span>Anon_{amigo.id}</span>
                                            :
                                            <span>{amigo.apodo}</span>
                                        }
                                    </div>
                                </Link>
                                );
                            })
                        }
                    </div>
                </div>
                <br/>
                {(this.state.page > 0)?
                    <button onClick={this.goPreviousPage}>{'<'}--Anterior Pagina</button>
                :<></>
                }
                <button onClick={this.goNextPage}>Proxima Pagina--{'>'}</button>
            </div>
        )
    }
}

export default FriendsMore