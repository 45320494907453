import React from 'react'
import '../root.css';
import './ads.css';

class AdsHomePage extends React.Component{
    render(){
        return (
                (parseInt(process.env.REACT_APP_ADS_ENABLED) === 1)?
                <div className="centerText adsContainer">
                    aca van los ads de la home page
                </div>
                :null
        )
    }
}

export default AdsHomePage