import React from 'react';
import AdsHomePage from '../Ads/AdsHomePage';
import CoolPeople from '../CoolPeople/coolPeople';
import '../root.css';
import SolicitudesAmistad from '../SolicitudesAmistad/SolicitudesAmistad';
import Thinking from '../Thinking/thinkingComponent';
import './homepage.css';
import axios from 'axios';
import {getToken} from '../token';
import { Link } from 'react-router-dom';
import PostsList from '../PostsList/PostsList';
import SocialShare from '../SocialShare/SocialShare';


class HomePage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            logged: true,
        };
    }

    componentDidMount(){
        //get Me
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);

        axios.post(process.env.REACT_APP_Base_Url + 'users/getMe',params).then(response => {
            console.log(response.data);
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    'idUser' : response.data.data.usuario.id, 
                    'apodo' : response.data.data.usuario.apodo,
                    'visits': response.data.data.usuario.visitas,
                    'friends': response.data.data.usuario.amigos,
                });
            }
        });
    }

    render(){
        return (
            <div className="centerText">
                <div className="flexRow">
                    <div className="threeCols panelStyle">
                        {
                            (typeof(this.state.apodo) !== 'undefined' &&
                                this.state.apodo.substring(0,5) !== 'Anon_')?
                                (<div>
                                    <h3>Bienvenido @{this.state.apodo}</h3>
                                    <div className="centerText smallFont">(<b>{this.state.friends}</b> Amigo/s con <b>{this.state.visits}</b> visitas a tu perfil)</div>
                                </div>)
                            :
                            <div className="centerText blinky">
                                <Link to="/settings">
                                    Por favor agrega un apodo e imagen de usuario.
                                </Link>
                                <br/>
                            </div>
                            
                        }
                        <div className="noBullets listaHome nullCenter">
                            <Link to={`/profile/@me`}>ver Perfil</Link><br/>
                            <Link to="/settings">Cambiar Apodo</Link><br/>
                            <Link to="/settings">Agregar/Editar Foto</Link><br/>
                            <Link to="/friendsRequest">Peticiones de Amistad</Link><br/>
                        </div>
                    </div>
                    <div className="threeCols2x panelStyle paddingThinkingHome">
                        <Thinking />
                    </div>
                </div>
                <div className="container">
                    <SocialShare />
                </div>
                
                <div className="container">
                    {/* <div className="containerItem flexColumn"> */}
                        <CoolPeople />
                        <br/>
                        <SolicitudesAmistad />
                    {/* </div> */}
                    {/* {
                        this.state.logged ? 
                        <div className="containerItem twoCols">
                            <FriendsActivity />
                            <br/>
                        </div>
                        :
                        <div className="containerItem twoCols">
                            <LoginComponent />
                            <br/>
                        </div>
                    } */}
                    
                </div>

                <AdsHomePage />
                <PostsList 
                        mode="trending" 
                />
            </div>
        )
    }
}

export default HomePage