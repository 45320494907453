import React from 'react'
import '../root.css';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import {getToken} from '../token';

class Redirector extends React.Component{
    state = {
        url: null,
        goMain: false,
    }
    
    componentDidMount(){
        //get Me
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        if ( token == null){
            this.setState({
                ...this.state,
                goMain:true,
            });
            return;
        }
        
        axios.post(process.env.REACT_APP_Base_Url + 'users/getMe',params).then(response => {
            console.log(response.data);
            if ( response.data.data.usuario){
                // this.setState({
                //     ...this.state,
                //     'idUser' : response.data.data.usuario.id, 
                //     'apodo' : response.data.data.usuario.apodo,
                // });
                const url = '/profile/' + response.data.data.usuario.apodo + 
                    '/' + response.data.data.usuario.id;

                this.setState({
                    ...this.state,
                    'url' : url,
                });
            }
        });
    }

    render(){
        return (
            <div className="centerText">
                Cargando...
                {
                    ( this.state.url)?
                        <Redirect to={this.state.url} />
                    :
                        <></>
                }
                {( this.state.goMain)?
                        <Redirect to="/" />
                    :
                        <></>
                }
            </div>
            
        )
    }
}

export default Redirector