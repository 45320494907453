import React from 'react'
import '../root.css';
import './amistad.css';
import {getToken} from '../token';
import axios from 'axios';
import { Link } from 'react-router-dom';

class SolicitudesAmistad extends React.Component{
    loading = false;
    state = {
        solicitudes : [],
    }

    constructor(props){
        super(props);
        this.cambiarEstadoSolicitud = this.cambiarEstadoSolicitud.bind(this);
        this.approbeAllRequests = this.approbeAllRequests.bind(this);
    }
    

    componentDidMount(){
        this.loadRequests();            
    }

    loadRequests(){
        this.loading= true;

        const token = getToken();
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token',token);
        

        axios.post(process.env.REACT_APP_Base_Url + 'friends/getPendingApproval',params).then(response => {
            this.loading = false;
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    solicitudes : response.data.data.pendientes,
                });
                
            }
        });
    }

    cambiarEstadoSolicitud(idOtro,nuevoEstado){
        const token = getToken();
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token',token);
        params.append('idOtro',idOtro);
        params.append('status',nuevoEstado);
        

        axios.post(process.env.REACT_APP_Base_Url + 'friends/changeApprovalStatus',params).then(response => {
            this.loading = false;
            if ( response.data.err === 0){
                const lista = this.state.solicitudes;
                
                for(var i=0; i < lista.length;i++){
                    if (parseInt(lista[i].idUser) === parseInt(idOtro)){
                        lista.splice(i,1);
                        break;
                    }
                }
                this.setState({
                    ...this.state,
                    solicitudes : lista,
                });
            }
        });
    }

    approbeAllRequests(){
        const token = getToken();
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token',token);
               

        axios.post(process.env.REACT_APP_Base_Url + 'friends/approveAllRequests',params).then(response => {
            this.loading = false;
            if ( response.data.err === 0){              
                this.setState({
                    ...this.state,
                    solicitudes : [],
                });
            }
        });
    }

    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }

    render(){
        return (
            <div className="centerText amigos">
                {
                    this.state.solicitudes.length === 0
                    ? ('No tienes peticiones de amistad')
                    : 
                    (
                        
                        <div className="solicitudes">
                                <b className="centerText">Peticiones de amistad</b>
                                <br/>
                                <button onClick={this.approbeAllRequests} className="aprobarTodos">Aprobar todas las peticiones</button>
                                <br/>
                                {this.state.solicitudes.map(user1 => {
                                    return (
                                        <div className="personRequest centerVertically">
                                            
                                                <div className="parteIzquierda">
                                                    <Link to={this.amigoUrl(user1.idUser,user1.apodo)} key={user1.id} >
                                                        {(user1.pathRel === null)?
                                                            <img src={process.env.REACT_APP_Default_Image} alt="oops" />
                                                        :
                                                            <img src={user1.pathRel} alt="oops" />
                                                        }
                                                        {
                                                            (user1.apodo === null) ?
                                                                <span>Anon_{user1.idUser}</span>
                                                            :
                                                                <span>{user1.apodo}</span>
                                                        }
                                                    </Link>        
                                                </div>
                                            
                                            <div className="parteDerecha">
                                                <button onClick={() => this.cambiarEstadoSolicitud(user1.idUser,100)}>Aceptar</button>
                                                <button onClick={() => this.cambiarEstadoSolicitud(user1.idUser,102)}>Rechazar</button>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                        </div>
                                
                    )
                }

                
            </div>
        )
    }
}

export default SolicitudesAmistad