import React from 'react'
import FriendsSuggest from '../FriendsSuggest/FriendsSuggest';
import '../root.css';
//import './UsersBrowserStyles.css';
import {getToken} from '../token';
import axios from 'axios';
import { Link } from 'react-router-dom';

class Friends extends React.Component{
    state = {
        friends: [],
        query : '',
        page : 0,
        mode : 'friends',
    };

    constructor(props){
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.search = this.search.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        this.goPreviousPage = this.goPreviousPage.bind(this);
    }

    componentDidMount(){
        this.getMisAmigos(0);
    }

    //solo buscar usuarios.
    search(page=0){
        const token = getToken();
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('query', this.state.query);
        params.append('page', page);

        axios.post(process.env.REACT_APP_Base_Url + 'users/search',params).then(response => {
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    friends: response.data.data.usuarios,
                    query: response.data.data.query,
                    page : page,
                    mode: 'search'
                });                
            }
        });
    }

    getMisAmigos(page){
        const token = getToken();
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('page', page);

        axios.post(process.env.REACT_APP_Base_Url + 'friends/getMyFriends',params).then(response => {
            console.log(response);
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    friends: response.data.data.friends,
                    page : page,
                    mode: 'friends',
                });
            }
        });
    }

    

    handleInput(e){
        //debugger;
        this.setState({
            ...this.state,
            query: e.target.value,
        });
        console.log(e.target.value);
    }

    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }
    
    goNextPage(){
        const nextPage = parseInt(this.state.page) + 1;
        if ( this.state.mode === 'friends')
            this.getMisAmigos(nextPage);
        else
            this.search(nextPage);
    }

    goPreviousPage(){
        if ( this.state.page >0){
            const nextPage = parseInt(this.state.page)-1;
            if ( this.state.mode === 'friends')
                this.getMisAmigos(nextPage);
            else
                this.search(nextPage);
        }
    }

    render(){
        return (
            <div className="centerText">
                <FriendsSuggest 
                    onInput={this.handleInput} 
                    onSearch={this.search}/>

                <h2>Tus Amigos...</h2>
                <div className="usersList usersList">
                    <div className="personas">
                        {
                            (this.state.friends.length === 0)?
                                <h2>Sin amigos por ahora.</h2>
                            : null
                        }
                        
                        {
                            this.state.friends.map(amigo => {
                                return (<Link key={amigo.id} to={this.amigoUrl(amigo.idUser,amigo.apodo)}>
                                    <div  className="person">
                                        {(amigo.pathRel === null || amigo.pathRel === '' || typeof(amigo.pathRel)=='undefined') ?
                                            <img src={process.env.REACT_APP_Default_Image} alt="oops" />
                                        :
                                        <img src={amigo.pathRel} alt="oops" />
                                        }
                                        <br/>
                                        {(amigo.apodo === null)?
                                            <span>Anon_{amigo.id}</span>
                                            :
                                            <span>{amigo.apodo}</span>
                                        }
                                    </div>
                                </Link>
                                );
                            })
                        }
                    </div>
                </div>
                {(this.state.page > 0)?
                <button onClick={this.goPreviousPage}>{'<'}--Anterior Pagina</button>
                :<></>
                }
                <button onClick={this.goNextPage}>Proxima Pagina--{'>'}</button>
            </div>
        )
    }
}

export default Friends