import React from 'react'
const axios = require("axios");

class ReactUploadIcon extends React.Component {

    constructor(props) {
        super(props);
        //console.log(props);
        

        this.state ={
            file: null,
            //onUpdate : this.props.onPorcentage,
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onNothing = this.onNothing.bind(this);
        //this.onPorcentage = this.onPorcentage.bind(this);

    }

    onFormSubmit(e){
        //callback
        this.props.onStart();

        console.log(process.env);
        console.log(process.env.REACT_APP_Upload_URL);

        //e.preventDefault();
        const formData = new FormData();
        // if ( this.state.file == null){
        formData.append('image',e.target.files[0]);
        // }else{
        //formData.append('image',this.state.file);
        //}
        
        //formData.append('image', this.state.file, this.state.file.fileName);

        const config = {
            headers: {
                //'content-type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent){
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                // do whatever you like with the percentage complete
                // maybe dispatch an action that will update a progress bar or something
                this.setState({
                    uploadProgress : percentCompleted,
                });
                console.log(percentCompleted + "%");
                console.log(this.props);
                if (this.props.onUpdate){
                    this.props.onUpdate(percentCompleted);
                }
            }.bind(this),
        }

        axios.post(process.env.REACT_APP_Upload_URL,formData,config)
            .then((response) => {
                console.log(response);
                //alert("The file is successfully uploaded");
                this.props.onChange(response.data);
                // this.setState({

                // });
            }).catch((error) => {
        });
    }
    onChange(e) {
        e.preventDefault();
        console.log('onChange');
        console.log(e.target.files);
        this.setState({file:e.target.files[0]});
        this.onFormSubmit(e);
    }

    onClick(){
        console.log('onClick');
        const imagen = document.getElementsByName("myImage")[0];
        imagen.click();
    }

    onNothing(e){
        e.preventDefault();
    }

    // onPorcentage(){
    //     this.props.onUpdate(this.state.uploadProgress);
    // }

    render() {
        return (
            <form onSubmit={this.onNothing}>
                <input accept="image/*" className="oculto" type="file" name="myImage" onChange= {this.onChange} />
                <img className="smallImage" src={process.env.PUBLIC_URL + "/assets/icons/addPhoto.png"} alt="AddPhoto" onClick={this.onClick} />
            </form>
            // <form onSubmit={this.onFormSubmit}>
            //     <input className="oculto" type="file" name="myImage" onChange= {this.onChange} />
            //     <button type="submit">{this.props.text}</button>
            // </form>
        )
    }
}

export default ReactUploadIcon

//https://medium.com/@mahesh_joshi/reactjs-nodejs-upload-image-how-to-upload-image-using-reactjs-and-nodejs-multer-918dc66d304c
//https://medium.com/@mahesh_joshi/reactjs-nodejs-upload-image-how-to-upload-image-using-reactjs-and-nodejs-multer-918dc66d304c