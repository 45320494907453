import React from 'react'
import '../root.css';
import './Preguntas.css';
import axios from 'axios';
import {getToken} from '../token';

class AddPregunta extends React.Component{
    state = {
        pregunta : '',
    };
    
    constructor(props){
        super(props);
        this.validar = this.validar.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput(e){
        this.setState({
            ...this.state,
            pregunta : e.target.value
        });
    }

    validar(){
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token',getToken());
        params.append('idOp', this.props.idUsuario);
        params.append('pregunta',this.state.pregunta);

        axios.post(process.env.REACT_APP_Base_Url + 'preguntas/add',params).then(response => {

            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    pregunta: ''
                });
                alert('Pregunta enviada OK')
            }

            if ( this.props.onPreguntado)
                this.props.onPreguntado();
        });
    }


    render(){
        return (
            <div className="centerText">
                <input style={{'width':'66%'}}
                    type="text" value={this.state.pregunta} 
                    onChange={this.handleInput}
                    placeholder='Escribe tu pregunta' />
                <button onClick={this.validar}>Preguntar</button>
            </div>
        )
    }
}

export default AddPregunta