import React from 'react'
import FriendsSuggest from '../FriendsSuggest/FriendsSuggest';
import '../root.css';
import './UsersBrowserStyles.css';
import {getToken} from '../token';
import axios from 'axios';
import { Link } from 'react-router-dom';

class UsersBrowser extends React.Component{
    loading = false;
    state = {
        usuarios: [],
        query : '',
        page : 0,
        mode: 'cool',
    };

    constructor(props){
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.search = this.search.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        this.goPreviousPage = this.goPreviousPage.bind(this);
    }
    componentDidMount(){
        this.loadCoolPeople(0);
    }

    loadCoolPeople(page){
        //pedir aca el usuario.
        var params = new URLSearchParams();
        //params.append('idUsuario', idUser);
        //params.append('cantidad',30);
        params.append('page',page);
        
        axios.post(process.env.REACT_APP_Base_Url + 'users/coolPeoplePaged',params).then(response => {
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    'usuarios':response.data.data.cool,
                    'page': page,
                });
                
            }
        });
    }

    goNextPage(){
        // this.setState({
        //     'page' : this.state.page +1,
        // });
        //debugger;
        const nextPage = parseInt(this.state.page)+1;

        if (this.state.mode === 'cool'){
            this.loadCoolPeople(nextPage);
        }else{
            this.search(nextPage);
        }
    }

    goPreviousPage(){
        if ( this.state.page >0){
            // this.setState({
            //     'page' : this.state.page -1,
            // });
            const nextPage = parseInt(this.state.page)-1;

            if (this.state.mode === 'cool'){
                this.loadCoolPeople(nextPage);
            }else{
                this.search(nextPage);
            }
        }
        
    }

    search(page){
        if ( page == null || isNaN(page)){
            page = 0;
        }

        this.loading= true;
        const token = getToken();
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('token', token);
        params.append('query', this.state.query);
        params.append('page',page);

        axios.post(process.env.REACT_APP_Base_Url + 'users/search',params).then(response => {
            this.loading = false;
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    usuarios: response.data.data.usuarios,
                    query: response.data.data.query,
                    mode: 'search',
                    page: page,
                });                
            }
        });
    }

    handleInput(e){
        //debugger;
        this.setState({
            ...this.state,
            query: e.target.value.trim(),
        });
        console.log(e.target.value);
    }

    amigoUrl(id,apodo){
        return "/profile/" + apodo + "/" + id;
    }
    
    render(){
        return (
            <div className="centerText">
                <FriendsSuggest
                    onInput={this.handleInput} 
                    onSearch={this.search}/>

                <h2>Encuentra nuevos amigos...</h2>
                <div className="usersList usersList">
                    <div className="personas">
                        {
                            (this.state.usuarios.length === 0)?
                                <b>No se encontraron resultados</b>
                            :
                            <></>
                        }


                        {
                            this.state.usuarios.map(amigo => {
                                return (<Link key={amigo.id} to={this.amigoUrl(amigo.id,amigo.apodo)}>
                                    <div  className="person">
                                        {(amigo.pathRel === null || amigo.pathRel === '' || typeof(amigo.pathRel)=='undefined') ?
                                            <img src={process.env.REACT_APP_Default_Image} alt="oops" />
                                        :
                                        <img src={amigo.pathRel} alt="oops" />
                                        }
                                        <br/>
                                        {(amigo.apodo === null)?
                                            <span>Anon_{amigo.id}</span>
                                            :
                                            <span>{amigo.apodo}</span>
                                        }
                                    </div>
                                </Link>
                                );
                            })
                        }
                    </div>
                </div>
                {(this.state.page > 0)?
                <button onClick={this.goPreviousPage}>{'<'}--Anterior Pagina</button>
                :<></>
                }
                <button onClick={this.goNextPage}>Proxima pagina--{'>'}</button>
            </div>
        )
    }
}

export default UsersBrowser