import React from 'react'
import '../root.css';

class FriendsSuggest extends React.Component{
    constructor(props){
        super(props);
        this.handleKeys = this.handleKeys.bind(this);
    }

    handleKeys(e){
        if ( e.which === 13){
            this.props.onSearch(0);
        }
    }

    render(){
        return (
            <div className="searchUsers">
                <input type="text"  
                    onKeyUp={this.handleKeys}
                    onChange={($event) => this.props.onInput($event)} placeholder="Busca usuarios x apodo o email"></input>
                <button onClick={() => this.props.onSearch(0)}>Buscar</button>
            </div>
        )
    }
}

export default FriendsSuggest