import HeaderTop from './headerTop';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import About from './About/about';
import './root.css'
import HomePage from './Home/homepage';
import Working from './Working/Working';
import UsersBrowser from './UsersBrowser/UsersBrowser';
import Friends from './Friends/Friends';
import Settings from './Settings/SettingsPage';
import Profile from './Profile/Profile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Networks from './Networks/Networks';
import Footer from './Footer/Footer';
import ScrollToTop from './Utils/ScrollToTop';
import Redirector from './Utils/Redirector';
import SolicitudesListado from './SolicitudesListado/SolicitudesListado';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import FriendsMore from './FriendsMore/FriendsMore';

function App() {
  return (
        
      <BrowserRouter basename="/network">
        
        <HeaderTop />
        <ToastContainer className="toastMio" />
        <ScrollToTop>
        
          <div className="mainContainer">
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/profile/:name/:id" exact component={Profile} />
              <Route path="/profile/@me" exact component={Redirector} />
              <Route path="/friends" exact component={Friends} />
              <Route path="/moreFriendsOf/:apodo/:id" exact component={FriendsMore} />
              <Route path="/friendsRequest" exact component={SolicitudesListado} />
              <Route path="/browse" exact component={UsersBrowser} />
              <Route path="/settings" exact component={Settings} />
              <Route path="/networks" exact component={Networks} />
              <Route path="/about" exact component={About} />
              <Route path="/help" exact component={Working} />
              <Route path="/policy" exact component={PrivacyPolicy} />
              <Route path="*" component={Working} />
            </Switch>
          </div>
        </ScrollToTop>
        <Footer />
        
      </BrowserRouter> 
    
       
  );
}


window.alert = function(msg){
  toast(msg,{
    toastId: 'toastMioId',
    className: 'toastMio',
  })
}

console.log = function(data){
  //hacer nada.
}

console.warning = function(data){
  //hacer nada.
}

export default App;
