import React from 'react'
import '../root.css';
import './ProfileStyles.css';
import Thinking from '../Thinking/thinkingComponent';
// import LoadingOverlay from 'react-loading-overlay';
import {getMe, getToken} from '../token';
import axios from 'axios';
import Modal from '../Utils/Modal';
import PostsList from '../PostsList/PostsList';
import SocialShare from '../SocialShare/SocialShare';
import FriendButton from './FriendButton';
import ProfileTabs from './ProfileTabs';
import ProfileFriends from './ProfileFriends';
import Preguntas from '../Preguntas/Preguntas';


class Profile extends React.Component{
    loading = false;
    child = null;

    constructor(props){
        super(props);
        //console.log(props);
        this.child = React.createRef();

        this.state = {
            id : props.match.params.id,
            apodo: 'Cargando...',
            friends : 0,
            pathRel: '',
            user:null,
            friendsList : null,
            lastUser : 0,
            showModal: false,
            imageToZoom : null,
            aboutme: null,
            me : getMe(),
            tab : 'default',
        };

        //this.beFriends = this.beFriends.bind(this);
        this.zoomImage = this.zoomImage.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.onNewPost = this.onNewPost.bind(this);
        this.tabChange = this.tabChange.bind(this);

        //this.getUserFriends = this.getUserFriends.bind(this);

        //no por ahora.
        // if ( this.props.openPost != null){

        //     //adaptarlo antes.
        //     //this.child.current.loadPosts(this.state.id,'user');
        // }
    }

    


    showModal(img){
        this.setState({
            ...this.state,
            showModal:true,
            imageToZoom : img,
        });
    }

    hideModal(){
        this.setState({
            ...this.state,
            showModal:false,
            imageToZoom : null,
        });   
    }

    zoomImage(img){
        this.showModal(img);
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        //cerrar el modal
        this.setState({
            ...this.state,
            showModal:false,
            imageToZoom : null,
        });

        if ( parseInt(nextProps.match.params.id) !== this.state.id && ! this.loading){
            console.log('nextProps:' + parseInt(nextProps.match.params.id));
            console.log('state:' + this.state.id);
   
            this.loadUsuario(parseInt(nextProps.match.params.id));
        }
        
    }

    componentDidMount(){

        this.loadUsuario(this.state.id);
    }

    loadUsuario(idUser){
        this.loading= true;

        
        //pedir aca el usuario.
        var params = new URLSearchParams();
        params.append('idUsuario', idUser);
        params.append('section','profile');
//debugger;
        axios.post(process.env.REACT_APP_Base_Url + 'users/getOne',params).then(response => {
            this.loading = false;
            if ( response.data.err === 0){
                this.setState({
                    ...this.state,
                    'id':response.data.data.usuario.id,
                    'apodo':response.data.data.usuario.apodo,
                    'pathRel':response.data.data.usuario.pathRel,
                    'friends' : response.data.data.usuario.amigos,
                    'aboutme' : response.data.data.usuario.aboutme,
                    'friendsList': [],
                });
                
            }
        });
    }

    onNewPost(){
        this.child?.current?.loadPosts(this.state.id,'user');
    }

    reportUser(userId){
        if ( window.confirm("Report User?")){
            var params = new URLSearchParams();
            params.append('token', getToken());
            params.append('uuidObj', userId);
            params.append('razon', 400);//razon default

            axios.post(process.env.REACT_APP_Base_Url + 'reports/addUserReport', params).then(response => {
                if (response.data.err === 0) {
                    alert('Reporte enviado');
                }
            });
        }
    }
    
    tabChange(tab){
        this.setState({
            ...this.state,
            tab: tab,
        });

        //cambiar de tab.
        console.log('tab => '+ tab);
    }
    
    

    render(){
        return (
            <>
                <Modal show={this.state.showModal} handleClose={this.hideModal}>
                    <div className="centerText">
                        <img onClick={this.hideModal} src={this.state.imageToZoom} className="imagenZoom imagenMedium" alt="Loading..." />
                    </div>
                    <br/>
                    {/* <Comentarios id={this.state.id}/> */}
                </Modal>
                <div className="centerText profileContainer panelStyle ">
                    <div className="flexRow fullWidth">
                        <div className="halfWidth">
                            {
                                (this.state.pathRel == null) ?
                                    <img onClick={() => this.zoomImage(process.env.REACT_APP_Default_Image)} className="avatarImg" src={process.env.REACT_APP_Default_Image} alt="avatar" />
                                :
                                    <img onClick={() => this.zoomImage(this.state.pathRel)} className="avatarImg" src={this.state.pathRel} alt="avatar" />
                            }
                        </div>
                        <div style={{"margin":"5px"}} className="flexColumn">
                            <div>
                                Perfil de <b style={{"fontSize":"32px"}}>{this.state.apodo}</b>
                            </div>
                            <br/>
                            <FriendButton 
                                id={this.state.id} 
                                friends={this.state.friends} 
                                apodo={this.state.apodo}
                            />
                            <br/>
                            {
                                (this.state.aboutme !== '' && this.state.aboutme !== null)?
                                    <div><b>Acerca de mi:</b> {this.state.aboutme}</div>
                                :null
                            }
                            {/* <ProfileNetworks id={this.state.id}/> */}
                        </div>
                    </div>
                </div>
                <div>
                    <SocialShare />
                    <ProfileTabs onChange={this.tabChange} 
                        active={this.state.tab} />
                </div>
                <div>
                    {(this.state.tab === 'posts' || this.state.tab === 'default')?
                        <div className="panelStyle postList">
                            <PostsList 
                                ref={this.child}
                                mode="user" 
                                idUsuario={this.state.id} />
                        </div>
                    :null}
                    {(this.state.tab === 'preguntas anonimas' )?
                        <div className="panelStyle postList">
                            <Preguntas idUsuario={this.state.id} />
                        </div>
                    :null
                    }

                    {(this.state.tab === 'amigos' )?
                        <div className="panelStyle postList">
                            <ProfileFriends
                                id={this.state.id} />
                        </div>
                    :null
                    }

                </div>
                {
                    (this.state.id === parseInt(this.state.me)) ? 
                        <div className="pegadoAlBottom halfWidth">
                            <Thinking className="halfWidth"  
                                onNewPost={this.onNewPost} 
                                message="Escribe algo ..." />
                        </div>
                    :null
                }
                
            </>
        )
    }
}

export default Profile